<template>
   <div class="col-12">
      <div class="card hover mb-1" :class="index % 2 == 1 ? 'invert' : ''">
         <div class="card-body py-10 px-0">
            <div class="row text-center" :class="outrasFormasPgto ? 'row-cols-lg-6 row-cols-xl-7' : 'row-cols-lg-5 row-cols-xl-6'">
               <div class="pdv">{{ pdv.pdv }}</div>
               <div class="col" :class="outrasFormasPgto ? 'd-none' : 'd-none d-xl-block'">
                  <span class="cursor-pointer" @click="buscarComprovantes('SANGRIA')">
                     {{ parseFloat(pdv.qtdSangria).toLocaleString('pt-br', {minimumFractionDigits: 0, maximumFractionDigits:3}) }}
                  </span>
               </div>
               <div class="col" :class="outrasFormasPgto ? 'd-none' : ''">
                  <span class="cursor-pointer" @click="buscarComprovantes('SANGRIA')">
                     <small class="font-10 d-none d-sm-inline">R$</small> {{ parseFloat(pdv.valorSangria).toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits:2}) }}
                  </span>
               </div>
               <div class="col" :class="outrasFormasPgto ? 'd-none' : ''">
                  <small class="font-10 d-none d-sm-inline">R$</small> {{ parseFloat(pdv.valorCaixa).toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits:2}) }}
               </div>
               <div class="col" :class="outrasFormasPgto ? 'd-none' : ''">
                  <span class="cursor-pointer" @click="buscarComprovantes('DINHEIRO')">
                     <small class="font-10 d-none d-sm-inline">R$</small> {{ parseFloat(pdv.valorDinheiro).toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits:2}) }}
                  </span>
               </div>
               <div class="col" :class="outrasFormasPgto ? 'd-xl-block d-none' : 'd-none'">
                  <span class="cursor-pointer" @click="buscarComprovantes('CONVENIO')">
                     <small class="font-10 d-none d-sm-inline">R$</small> {{ parseFloat(pdv.valorConvenio).toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits:2}) }}
                  </span>
               </div>
               <div class="col" :class="outrasFormasPgto ? '' : 'd-none'">
                  <span class="cursor-pointer" @click="buscarComprovantes('PAGAMENTO_INSTANTANEO')">
                     <small class="font-10 d-none d-sm-inline">R$</small> {{ parseFloat(pdv.valorPix).toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits:2}) }}
                  </span>
               </div>
               <div class="col" :class="outrasFormasPgto ? '' : 'd-none'">
                  <span class="cursor-pointer" @click="buscarComprovantes('PAGAMENTO_INSTANTANEO (POS)')">
                     <small class="font-10 d-none d-sm-inline">R$</small> {{ parseFloat(pdv.valorPixPos).toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits:2}) }}
                  </span>
               </div>
               <div class="col" :class="outrasFormasPgto ? '' : 'd-none'">
                  <span class="cursor-pointer" @click="buscarComprovantes('CARTAO_DE_DEBITO')">
                     <small class="font-10 d-none d-sm-inline">R$</small> {{ parseFloat(pdv.valorDebito).toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits:2}) }}
                  </span>
               </div>
               <div class="col" :class="outrasFormasPgto ? '' : 'd-none'">
                  <span class="cursor-pointer" @click="buscarComprovantes('CARTAO_DE_DEBITO (POS)')">
                     <small class="font-10 d-none d-sm-inline">R$</small> {{ parseFloat(pdv.valorDebitoPos).toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits:2}) }}
                  </span>
               </div>
               <div class="col" :class="outrasFormasPgto ? '' : 'd-none'">
                  <span class="cursor-pointer" @click="buscarComprovantes('CARTAO_DE_CREDITO')">
                     <small class="font-10 d-none d-sm-inline">R$</small> {{ parseFloat(pdv.valorCredito).toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits:2}) }}
                  </span>
               </div>
               <div class="col" :class="outrasFormasPgto ? '' : 'd-none'">
                  <span class="cursor-pointer" @click="buscarComprovantes('CARTAO_DE_CREDITO (POS)')">
                     <small class="font-10 d-none d-sm-inline">R$</small> {{ parseFloat(pdv.valorCreditoPos).toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits:2}) }}
                  </span>
               </div>
               <div class="col" :class="outrasFormasPgto ? 'd-none' : ''">
                  <span class="cursor-pointer" @click="buscarComprovantes('OUTROS')">
                     <small class="font-10 d-none d-sm-inline">R$</small> {{ parseFloat(pdv.valorOutros).toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits:2}) }}
                  </span>
               </div>
               <div class="col weight-600" :class="outrasFormasPgto ? 'd-none' : ''">
                  <span class="cursor-pointer" @click="buscarComprovantes('TOTAL')">
                     <small class="font-10 d-none d-sm-inline">R$</small> {{ parseFloat(pdv.valorTotal).toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits:2}) }}
                  </span>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>

export default {
	name: 'Pdv',
   props: ['pdv', 'index', 'outrasFormasPgto'],
   methods: {
      buscarComprovantes : function (formaPagamento) {
         this.$emit('buscarComprovantes', {
            'pdv': this.pdv.idPdv,
            'numeroPdv': this.pdv.pdv,
            'formaPagamento': formaPagamento
         })
      }
   }
}

</script>

<style scoped>

* {
   font-size: 13.5px;
}

.pdv {
   width: 90px;
}

@media (min-width: 992px) {
   .row-cols-lg-5>*:not(.pdv) {
      flex: 0 0 auto;
      width: calc(20% - 18px);
   }
   
   .row-cols-lg-6>*:not(.pdv) {
      flex: 0 0 auto;
      width: calc(16.666666666666668% - 15px);
   }
}

@media (min-width: 1200px) {
   .row-cols-xl-6>*:not(.pdv) {
      flex: 0 0 auto;
      width: calc(16.666666666666668% - 15px);
   }

   .row-cols-xl-7>*:not(.pdv) {
      flex: 0 0 auto;
      width: calc(14.28% - 12.85px);
   }
}

</style>