<template>
   <div class="row">
      <div class="col-12" v-if="telaAjuda[$route.path.substring(1, $route.path.lastIndexOf('/'))]">
         <div class="alert alert-secondary alert-dismissible fade show mb-2" role="alert">
            <strong>{{ $route.name != null ? $route.name.replace(/_/g, " ").replace(/-/g, "/") : '' }}</strong><i class="far fa-arrow-alt-right font-10 mx-2"></i>
            <span>Acompanhe vendas e sangrias contabilizadas em suas lojas. Clique sobre um valor para visualizar detalhadamente cada cupom gerado pelo caixa.</span>
            <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close" @click="$store.dispatch('toggleTelaAjuda', $route.path.substring(1, $route.path.lastIndexOf('/')))"></button>
         </div>
      </div>

      <div id="search-nav" class="col-12 d-block btn-group dropup">
         <button type="button" class="btn btn-primary dropdown-toggle d-block d-lg-none" data-bs-toggle="dropdown" data-bs-auto-close="false" aria-expanded="false">
            <i class="far fa-search font-17 mx-auto"></i><i class="fal fa-times font-17 mx-auto"></i>
         </button>
         <div class="card mb-2 dropdown-menu dropdown-menu-left">
            <div class="card-body pb-0">
               <div class="row">
                  <div class="col-6 col-md-5 mb-3">
                     <label><i class="far fa-store color-theme font-11 me-1"></i> Loja</label>
                     <v-select name="Desktop" :options="dadosUsuario.usuarioLojas" v-model="pesquisa.loja" label="nomeFantasia" :reduce="loja => loja.id" placeholder="Todas as lojas">
                        <template #option="option">{{ option.nomeFantasia }}<small class="mx-2"><i class="fal fa-grip-lines-vertical"></i></small><i class="far fa-tag font-11 me-1"></i> {{ option.numeroLoja }}</template>
                        <template v-slot:no-options>
									<span class="d-block lh-sm my-1">Sem resultados</span><small class="d-block lh-sm mb-1 opacity-90 font-11">Solicite permissão ou utilize a tela "Lojas" para adicionar</small>
								</template>
                     </v-select>
                     <select name="Mobile" class="form-control" v-model="pesquisa.loja">
                        <option v-for="(option, index) in dadosUsuario.usuarioLojas" :key="index" :value="option.id">{{ option.nomeFantasia }}&nbsp;&nbsp;|&nbsp;&nbsp;nº {{ option.numeroLoja }}</option>
                     </select>
                  </div>
                  <div class="col-6 col-md-5 col-xl-6 mb-3">
                     <label><i class="far fa-calendar color-theme font-11 me-1"></i> Data</label>
                     <datePicker format="DD/MM/YYYY" :range="true" :clearable="false" :editable="false" v-model="pesquisa.data"></datePicker>
                  </div>
                  <div class="col-md-2 col-xl-1 mb-3 align-self-end text-center">
                     <button type="button" class="btn btn-primary w-100" @click="buscarVendas"><i class="fas fa-search font-13"></i></button>
                  </div>
               </div>
            </div>
         </div>
      </div>

      <div class="col-12" v-if="pesquisa.resultado.length > 0">
         <loja v-for="(loja, index) in pesquisa.resultado" :key="index" :loja="loja" :index="index" @buscarComprovantes="buscarComprovantes($event, false)" />
      </div>

      <!-- modalComprovantes -->
      <div class="modal fade" id="modalComprovantes" tabindex="-1" aria-labelledby="modalComprovantesLabel" aria-hidden="true">
         <div class="modal-dialog modal-md h-100 modal-dialog-centered modal-dialog-scrollable">
            <div class="modal-content h-100">
               <div class="modal-header">
                  <h5 class="modal-title" id="modalComprovantesLabel">Comprovantes - Loja {{ modal.numeroLoja }} - Caixa {{ modal.numeroPdv }}</h5>
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
               </div>
               <div class="modal-body" v-if="modal.resultado.length > 0">
                  <div class="cards-150 cards-sm-200">
                     <comprovante v-for="(cupom, index) in modal.resultado" :key="index" :index="index" :cupom="cupom" :preview="true" @visualizar="verComprovante($event)" />
                  </div>

                  <div class="w-100 mt-3 mb-2 text-center" v-if="modal.resultado.length % 30 == 0">
                     <button type="button" class="btn btn-secondary btn-md font-12" @click="buscarComprovantes(null, false)">Ver mais</button>
                  </div>
               </div>
               <div class="modal-body my-5 text-center" v-else>
                  <i class="fal fa-print-slash font-60 text-secondary opacity-50 d-block mb-3"></i>
                  <h4 class="font-12 mt-4 mb-0 text-secondary text-uppercase">Nenhum comprovante encontrado</h4>
               </div>
               <div class="modal-footer">
                  <button type="button" class="btn btn-primary" v-if="modal.resultado.length > 0" @click="buscarComprovantes(null, true)">Exportar</button>
                  <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Fechar</button>
               </div>
            </div>
         </div>
      </div>

      <modalComprovante :modalAnterior="'#modalComprovantes'" :tipo="comprovante.tipo" :comprovante="comprovante.dados" />
   </div>
</template>

<script>

import $ from 'jquery'
import { mapState } from 'vuex'
import loja from '@/components/resultados/vendas/Loja.vue'
import comprovante from '@/components/resultados/Comprovante.vue'
import modalComprovante from '@/components/resultados/ModalComprovante.vue'

export default {
	name: 'Vendas',
	data: function () {
      return {
         pesquisa: {'loja': null, 'data': [new Date(), new Date()], 'resultado': []},
			modal: {'pdv': null, 'numeroPdv': null, 'idLoja': null, 'numeroLoja': null, 'dataInicial': null, 'dataFinal': null, 'formaPagamento': null, 'pagina': 0, 'resultado': []},
         comprovante: {'tipo': null, 'dados': null},
         listaExportacao: []
		}
	},
	computed: {
      ... mapState({
         dadosUsuario: state => state.dadosUsuario,
			telaAjuda: state => state.telaAjuda,
         urlRest: state => state.urlRest,
         tabs: state => state.tabs
      })
   },
   watch: {
		$data: {
			handler: function(value) {
				this.$store.dispatch('attTabData', JSON.parse(JSON.stringify({'id': this.$route.params.idTab, 'data': value})))
			},
			deep: true
		},
		'$route.params.idTab': function (value) {
         Object.assign(this.$data, this.tabs[value].data)
      },
      'pesquisa.data' : function (value) {
         for (let i = 0; i < value.length; i++) {
            if (!(value[i] instanceof Date)) {
               value[i] = new Date(value[i])
            }
         }
      }
	},
	components: {
      loja, comprovante, modalComprovante
   },
	methods: {
      verComprovante : function (comprovante) {
			this.comprovante = JSON.parse(JSON.stringify(comprovante))
			$("#modalComprovantes").modal('hide')
			$("#modalVerComprovante").modal('show')
		},
		buscarVendas : function () {
         if (Math.round((this.pesquisa.data[1].getTime() - this.pesquisa.data[0].getTime()) / (24 * 60 * 60 * 1000)) >= 90) {
				this.$toast.fire({
					icon: 'error',
					title: 'Período da busca maior que 90 dias.'
				});
	
				return
			}
         
			this.$store.dispatch('alternarTelaCarregamento', true)
			this.pesquisa.resultado = []
         if (this.pesquisa.loja == null) this.pesquisa.loja = 0;

			this.$axios({
				method: 'post',
				url: this.urlRest +'resultados/searchVendas',
				headers: {'Content-Type': 'application/json'},
				data: this.pesquisa
				
			}).then(response => {
				this.pesquisa.resultado = response.data

			}).catch((error) => {
				if (error.response != undefined) {
					if (error.response.status == 408) {
						this.$store.dispatch('deslogar')

						this.$toast.fire({
							icon: 'warning',
							title: 'Sessão expirada!'
						});
					} else {
						this.$toast.fire({
							icon: 'error',
							title: 'Erro: ' + error.response.status
						});
					}
				}
			}).finally(() => {
				this.$store.dispatch('alternarTelaCarregamento', false)
			});
      },
		buscarComprovantes : function (objLoja, isExcel) {
         this.$store.dispatch('alternarTelaCarregamento', true)
			this.modal = {
				'pdv': objLoja != null ? objLoja.pdv : this.modal.pdv, 
				'numeroPdv': objLoja != null ? objLoja.numeroPdv : this.modal.numeroPdv, 
				'idLoja': objLoja != null ? objLoja.idLoja : this.modal.idLoja, 
				'numeroLoja': objLoja != null ? objLoja.numeroLoja : this.modal.numeroLoja, 
				'dataInicial': objLoja != null ? objLoja.dataInicio : this.modal.dataInicial, 
				'dataFinal': objLoja != null ? objLoja.dataFim : this.modal.dataFinal, 
				'formaPagamento': objLoja != null ? objLoja.formaPagamento : this.modal.formaPagamento, 
				'pagina': isExcel ? -1 : (objLoja != null ? 0 : this.modal.pagina), 
				'resultado': objLoja != null ? [] : this.modal.resultado
			}

			$('#modalComprovantes').modal('show')
         Object.assign(this.$data.listaExportacao, this.$options.data.apply(this).listaExportacao)

         this.$axios({
            method: 'post',
            url: this.urlRest +'pdv/searchComprovantes',
				headers: {'Content-Type': 'application/json'},
				data: this.modal

         }).then(response => {
				if (isExcel) {
               this.listaExportacao = response.data
            } else {
               this.modal.pagina++;
               this.modal.resultado.push(... response.data)
            }
         }).catch((error) => {
            if (error.response != undefined) {
               if (error.response.status == 408) {
                  this.$store.dispatch('deslogar')

                  this.$toast.fire({
                     icon: 'warning',
                     title: 'Sessão expirada!'
                  });
               } else {
                  this.$toast.fire({
                     icon: 'error',
                     title: 'Erro: ' + error.response.status
                  });
               }
            }
         }).finally(() => {
            this.$store.dispatch('alternarTelaCarregamento', false)

            if (isExcel) {
               this.$store.dispatch('exportarExcel', {
                  'nome': 'Comprovantes',
                  'resultado': this.listaExportacao, 
                  'campos': this.modal.formaPagamento == 'SANGRIA' ? ['id', 'idPdv', 'data', 'valor'] : this.modal.formaPagamento == 'TOTAL' ? ['id', 'idPdv', 'data', 'valor'] : ['id', 'idPdv', 'formaPagto', 'data', 'valor'], 
                  'lista': null
               })
            }
         });
      }
	},
   mounted() {
      Object.assign(this.$data, this.tabs[this.$route.params.idTab].data)
   }
}

</script>